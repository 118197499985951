<template>
  <div class="sign-up d-flex flex-column align-center mx-4">
    <div class="text-h4 mt-16 mb-8">{{ $t("__sign_up_title") }}</div>
    <v-card class="ma-2" elevation="0" max-width="600" width="100%">
      <v-card-text class="pa-6">
        <v-form ref="form" v-model="valid">
          <!-- e-mail -->
          <div class="subtitle-1 mb-2">
            {{ $t("__email_address") }}
            <span class="red--text">*</span>
          </div>
          <v-text-field
            outlined
            dense
            v-model="form.email"
            type="email"
            :rules="[requiredRule, emailRule]"
            required
          />
          <!-- password -->
          <div class="subtitle-1 mb-2">
            {{ $t("__password") }}
            <span class="red--text">*</span>
          </div>
          <v-text-field
            outlined
            dense
            v-model="form.password"
            type="password"
            :rules="[requiredRule]"
            required
          />
          <!-- confirm password -->
          <div class="subtitle-1 mb-2">
            {{ $t("__confirm_password") }}
            <span class="red--text">*</span>
          </div>
          <v-text-field
            outlined
            dense
            v-model="form.confirmPassword"
            type="password"
            :rules="[requiredRule, confirmPasswordRule]"
            required
          />
          <!-- name -->
          <v-container fluid>
            <v-row>
              <v-col class="pa-0" :cols="isMobile ? 12 : 5.5">
                <div class="subtitle-1 mb-2">
                  {{ $t("__first_name") }}
                  <span class="red--text">*</span>
                </div>
                <v-text-field
                  outlined
                  dense
                  v-model="form.firstName"
                  type="text"
                  :rules="[requiredRule]"
                  required
                />
              </v-col>
              <v-col class="pa-0" :cols="isMobile ? 0 : 1" />
              <v-col class="pa-0" :cols="isMobile ? 12 : 5.5">
                <div class="subtitle-1 mb-2">
                  {{ $t("__last_name") }}
                  <span class="red--text">*</span>
                </div>
                <v-text-field
                  outlined
                  dense
                  v-model="form.lastName"
                  type="text"
                  :rules="[requiredRule]"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <!-- phone number -->
          <div class="subtitle-1 mb-2">
            {{ $t("__phone_number") }}
            <span class="red--text">*</span>
          </div>
          <phone-number-input
            outlined
            dense
            v-model="form.phoneNumber"
            :rules="[requiredRule]"
            required
          />
          <!-- invitation code -->
          <v-checkbox
            :label="$t('__invitation_code_checkbox')"
            @change="form.invitationCode = ''"
            v-model="haveInvitationCode"
          />

          <v-text-field
            v-if="haveInvitationCode"
            :label="$t('__invitation_code')"
            class="invitation-code-input"
            outlined
            dense
            v-model="form.invitationCode"
            type="text"
            :rules="[invitationCodeRule]"
            required
            @keyup="uppercaseInvitationCode"
          />
          <!-- error message -->
          <div class="subtitle-1 mb-2 error--text">{{ error }}</div>
        </v-form>
        <!-- privacy policy -->
        <v-row class="ma-0" align="center">
          <v-checkbox v-model="accepted" />
          <div class="subtitle-1">
            {{ $t("__sign_up_accept_message") }}
            <a @click="privacyPolicyShow = true">
              {{ $t("__privacy_policy") }}
            </a>
          </div>
        </v-row>
        <v-dialog
          max-width="600px"
          v-model="privacyPolicyShow"
          :fullscreen="isMobile"
        >
          <v-card tile elevation="0">
            <PrivacyPolicyCard />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="
                  privacyPolicyShow = false;
                  accepted = true;
                "
                >{{ $t("__accept") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- sign up -->
        <v-btn block color="success" @click="signUp" :disabled="!accepted">
          {{ $t("__sign_up") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PrivacyPolicyCard from "@/components/PrivacyPolicyCard";
import { mapGetters } from "vuex";
import { userAPI } from "@/plugins/service";

export default {
  name: "SignUp",
  components: {
    PrivacyPolicyCard
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        invitationCode: ""
      },
      accepted: false,
      privacyPolicyShow: false,
      valid: false,
      error: "",
      haveInvitationCode: false,
      requiredRule: v => !!v || this.$t("__required"),
      emailRule: v => /.+@.+\..+/.test(v) || this.$t("__email_address_invalid"),
      confirmPasswordRule: v =>
        v === this.form.password || this.$t("__confirm_password_not_same"),
      invitationCodeRule: v =>
        !v || /\b[0-9A-Z]{6}\b/.test(v) || this.$t("__invitation_code_invalid")
    };
  },
  methods: {
    uppercaseInvitationCode() {
      this.form.invitationCode = this.form.invitationCode.toUpperCase();
    },
    isInvitationCodeValid() {
      return new Promise((resolve, reject) => {
        if (this.form.invitationCode) {
          return userAPI
            .isInvitationCodeExist("", this.form.invitationCode)
            .then(result => {
              resolve(!!result["is_exist"]);
            })
            .catch(err => reject(err));
        } else {
          resolve(true);
        }
      });
    },
    signUp() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store.dispatch("setIsLoading", true);
        return this.isInvitationCodeValid()
          .then(isValid => {
            if (!isValid) {
              return Promise.reject(this.$t("__invitation_code_invalid"));
            }
          })
          .then(() => {
            return this.$store.dispatch("user/signUpWithEmailAndPassword", {
              email: this.form.email,
              password: this.form.password
            });
          })
          .then(() => {
            return this.$store.dispatch("user/updateUserName", {
              firstName: this.form.firstName,
              lastName: this.form.lastName
            });
          })
          .then(() => {
            return this.$store.dispatch(
              "user/updatePhoneNumber",
              this.form.phoneNumber
            );
          })
          .then(() => {
            if (this.form.invitationCode) {
              return this.$store.dispatch(
                "user/updateJoinInvitationCode",
                this.form.invitationCode
              );
            }
          })
          .then(() => {
            return this.$store.dispatch("user/getUserData");
          })
          .catch(err => {
            this.$store.dispatch("setIsLoading", false);
            const errCode = err.code;
            const errMessage = err.message;
            let errorMessage = "";
            if (errCode) {
              const tKey = `__firebase_error_${errCode}`;
              errorMessage = this.$t(tKey);
              if (errorMessage === tKey) {
                if (errMessage) {
                  errorMessage = errMessage;
                } else {
                  errorMessage = errCode;
                }
              }
            } else if (errMessage) {
              errorMessage = errMessage;
            } else {
              errorMessage = err;
            }
            this.error = errorMessage;
          });
      }
    }
  },
  watch: {
    "$store.state.user.data": {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch("setIsLoading", false);
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect);
          } else {
            this.$router.replace({ name: "Home" });
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.invitation-code-input input {
  text-transform: uppercase;
}
</style>
